import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hlr-lookup"
    }}>{`HLR Lookup`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`In order to use our hlr lookup service you need to send an http request to the following domain.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://hlr.anymessage.cloud"
        }}>{`https://hlr.anymessage.cloud`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "http-request"
    }}>{`Http request`}</h2>
    <p>{`Send a get request to `}<a parentName="p" {...{
        "href": "https://hlr.anymessage.cloud/lookup"
      }}>{`https://hlr.anymessage.cloud/lookup`}</a></p>
    <h3 {...{
      "id": "parameter"
    }}>{`Parameter`}</h3>
    <p>{`Add one required get parameter to your requests:`}</p>
    <ul>
      <li parentName="ul">{`msisdn = msisdn in international format without leading 00 or +`}</li>
    </ul>
    <h3 {...{
      "id": "authentication"
    }}>{`Authentication`}</h3>
    <p>{`You need a JSON Web Token (jwt) to authenticate yourself. This token is provided from us.`}</p>
    <p>{`We use bearer authentication. Add an authentication header to each request `}</p>
    <p>{`--header 'Authorization: Bearer your-Token`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <p>{`note: tokens are truncated`}</p>
    <p>{`all the following examples perform a lookup on msisdn = 00491520123456 (note leading 00 is removed on request)`}</p>
    <h4 {...{
      "id": "curl"
    }}>{`curl`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
curl --location --request GET 'https://hlr.anymessage.cloud/lookup?msisdn=491520123456' \\
--header 'Authorization: Bearer eyJhbGciOiJSUzI1NiIsI...' 

`}</code></pre>
    <h4 {...{
      "id": "nodejs"
    }}>{`nodejs`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
var request = require('request');
var options = {
  'method': 'GET',
  'url': 'https://hlr.anymessage.cloud/lookup?msisdn=491520123456',
  'headers': {
    'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsI...'
  }
};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
});


`}</code></pre>
    <h2 {...{
      "id": "http-response"
    }}>{`Http response`}</h2>
    <h3 {...{
      "id": "successful-response"
    }}>{`Successful response`}</h3>
    <p>{`You receive a 200 http status code and a json formatted response containing the following key value fields:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"000"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`error code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`human readable error description. empty string on success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`msisdn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"491520123456"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the msisdn you want to lookup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`country_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Germany"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`country name the msisdn belongs to`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`country_3166-1-alpha_2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"de"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`two-letter country codes defined in ISO 3166-1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`country_3166-1-alpha_3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"DEU"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`three-letter country codes defined in ISO 3166-1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`country_code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"49"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`country code of the msisdn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix_mcc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"262"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobil country code based on prefix`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix_mnc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"02"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobil network code based on prefix`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix_operator_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Vodafone D2 (Germany)"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operator name based on prefix`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`real_operator_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Vodafone D2 (Germany)"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operator name based on current operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`real_mcc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"262"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobil country code based current operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`real_mnc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"02"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobil network code based current operator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ported`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number portability status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subscriber_status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"active"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`subscriber status`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "erroneous-response"
    }}>{`Erroneous response`}</h3>
    <h4 {...{
      "id": "unauthorized"
    }}>{`Unauthorized`}</h4>
    <p>{`You receive a 401 Unauthorized http status code.`}</p>
    <p>{`Your provided jwt is invalid. `}</p>
    <h4 {...{
      "id": "error-code-unequal-to-000"
    }}>{`error code unequal to 000`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`error_code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`error_message`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"001"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"generic error"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a non specific error has occurred`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"002"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"no real network information available"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`we are not able to determine real network information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"003"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"wrong formatted request"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`your request is wrong formatted`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      